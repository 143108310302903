.PlanManagement {

    &__card {
        &__body {
            display: flex;
            flex-direction: column;
        }
    }

   
}