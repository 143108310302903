.TableCard {
    display: inline-block;
    font-size: 1.3rem;
    min-width: 35rem;
    max-height: 80vh;
    overflow: auto;
    font-weight: 600;
    background: #FFFFFF;
    table, thead {
        width: 100%;
    }
    thead tr {
        background: linear-gradient(174.45deg, #103C67 0%, #2E4D6B 101.12%);
        color: white;
        th {
            padding: 1.2rem 2rem;
        }
    }

    tbody tr {
        td {
            padding: 0.5rem 2rem 0.4rem 2rem;
            text-align: left;
            min-width: 15rem;
            //max-width: 30rem;
            color: #2E3E4E;

            &:last-child {
                color: rgba(0, 0, 0, 0.6);
            }
        }
        th {
            padding: 0.5rem 2rem 0.4rem 2rem;
            font-weight: 800;
        }
    }
}