.LoginPage {
  width: 30vw;
  height: 80vh;
  &__label {
    font-weight: bold;
    font-size: 3rem;
    line-height: 4.1rem;
    color: #23466a;
    text-align: center;
    padding-bottom: 2rem;
  }

  &__signup {
    text-align: center;
    font-size: 2rem;
    line-height: 6rem;
    color: #5C5C5C;
  }
  &__form {
      margin-top: 4rem;
  }
  &__app_links {
    font-weight: bold;
    font-size: 3rem;
    line-height: 4.1rem;
    color: #23466a;
    text-align: center;
    padding-bottom: 2rem;
  }
  &__app_links_container {
    font-weight: bold;
    font-size: 3rem;
    line-height: 4.1rem;
    color: #23466a;
    text-align: center;
    padding-bottom: 2rem;
  }
  &__forgot {
    text-align: right;
    line-height: 4.2rem;
    font-size: 1.8rem;
    color: #5C5C5C;
    margin-bottom: 5rem;
    &--link {
        color: #5C5C5C;
        &:link {
            text-decoration: none;
        }
      }
  }
  
}
