.SidebarDropDown {
    width: 100%;
    font-size: 1.3rem;
    white-space: nowrap;

    &__header {
        width: 100%;
        padding: 1rem 2rem;
        font-weight: 700;
        letter-spacing: .4px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        margin-bottom: .2rem;
        &:first-child {
            background-color: #48C1FC;
        } 
        &:last-child {
            background-color: #374B5E;
        }
    }

    &__body {
        overflow: auto;
        // padding: 0 1rem;
        &-item {
            width: 100%;
            padding: .7rem 2rem;
            background-color: #48C1FC1A;
            color: #FFFFFFCE;
            margin-bottom: .14rem;
            cursor: pointer;
            &:hover {
                background-color: darken($color: #48C1FC1A, $amount: 20%);
            }
            
        }
        &-item.active {
            background-color: #48c0fc50;
        }
        
        a, a:hover, a:focus, a:active {
            text-decoration: none;
            color: inherit;
            
        }
        a {
            margin-bottom: .14rem;
        }
        &.close {
            display: none;
        }
    }
}