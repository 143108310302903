*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  //font-size: 16px;
  //font-size: 62.5%;
  font-size: 50%;
  font-family: 'Nunito', sans-serif;
  
  @media screen and (min-width: 768px) {
    font-size: 52.5%;
  }

  @media screen and (min-width: 1400px) {
    font-size: 62.5%;
  }

  
} 

body {
  height: 100%;
  font-weight: 400;
  color: #103C67;
  font-family: 'Nunito', sans-serif;
  background-color: #E5E5E5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }

// for custum style
@import './styles/form';
@import './styles/button';
@import './styles/bootstrap';
@import './styles/variables';
@import './styles/animation';

.bg__card {
  padding: 2rem;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
}

.Status {
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  &__green {
    background: #0FB463;
    box-shadow: 0 0 .6rem #0FB463AA;
  }

  &__red {
    background: #D14600;
    box-shadow: 0 0 .6rem #D14600AA;
  }
}
.success_snackbar {
  border-left: .8rem solid #43A047 !important;
  background: white !important;
  color: #43A047 !important;
}
.error_snackbar {
  border-left: .8rem solid #D32F2F !important;
  background: white !important;
  color: #D32F2F !important;
}
.formErrorText {
  color: #D32F2F;
  font-size: 1.3rem;
}
.warning_snackbar {
  border-left: .6rem solid #FF9800 !important;
  background: white !important;
  color: #FF9800 !important;
}

.info_snackbar {
  border-left: .8rem solid #2979FF !important;
  background: white !important;
  color: #2979FF !important;
}

.scrollBar {
  &::-webkit-scrollbar {
    width: .5rem;
    height: .5rem;
  }
  &::-webkit-scrollbar-track, scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    box-shadow:  inset 0 0 6px rgba(0,0,0,0.3);
  }
  &::-webkit-scrollbar-thumb, scrollbar-thumb {
    background-color: lightgrey;
    //outline: 1px solid slategrey;
    border-radius: 1px;
  }
} 
.scrollBarDark {
  &::-webkit-scrollbar {
    width: .5rem;
    height: .5rem;
  }
  &::-webkit-scrollbar-track, scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  }
  &::-webkit-scrollbar-thumb, scrollbar-thumb {
    background-color: darkgrey;
    //outline: 1px solid slategrey;
    border-radius: 1px;
  }
} 

.icon_card {
  padding: 0.2rem 0.2rem;
  // box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  //border-radius: .2rem;
}

.Label__Required {
  position: relative;
  &::after {
    content: '*';
    color: red;
    position: absolute;
  }
}

// .MuiSelect-select {
//   font-size: 1.3rem !important;
//   font-family: 'nunito' !important;
// }

// .MuiSelect-nativeInput {
//   display: none !important;
//   border-bottom: none !important;
//   outline: none !important;
//   border-width: 0 !important;
// }

// Matrial UI select styling override

.MuiSelect-select, 
.MuiPaper-root, .MuiList-root {
  // padding: 1rem;
  font-size: 1.3rem !important;
  font-family: 'Nunito', sans-serif !important;
}

.MuiInputBase-input {
  padding: .7rem .5rem .7rem 1rem !important;
  background: white !important;
  border-radius:.2rem !important;
  border: 1.3px solid rgba($color: #000000, $alpha: 0.2) !important;
  outline: 2px solid transparent !important;

  // &:active {
  //   border: 1.3px solid rgba($color: #000000, $alpha: 0) !important;
  //   outline: 2px solid #48c1fc9f !important;
  // }
  &:hover {
    border-color: lightblue !important;
  }

 
}

.MuiInput-root.MuiInput-underline.MuiInputBase-root {
  &::before {
    content: none !important;
  }
  &::after {
    content: none !important;
  }
}

.MuiSvgIcon-root.MuiSelect-icon {
  right: .5rem !important;
}

// .MuiOutlinedInput-root {
//   font-size: 1.6rem !important;
//   font-weight: 600 !important;
//   font-family: 'Nunito' !important;
// }

// .MuiInputLabel-root {
//   font-size: 1.6em !important;
//   font-weight: 600 !important;
//   font-family: 'Nunito' !important;
// }

//// stripe styling

// .DemoPickerWrapper {
//   padding: 0 12px;
//   font-family: "Source Code Pro", monospace;
//   box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
//   border-radius: 3px;
//   background: white;
//   margin: 24px 0 48px;
//   width: 100%;
// }

// .DemoPicker {
//   font-size: 18px;
//   border-radius: 3px;
//   background-color: white;
//   height: 48px;
//   font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
//   border: 0;
//   width: 100%;
//   color: #6772e5;
//   outline: none;
//   background: transparent;

//   -webkit-appearance: none;
// }

// .DemoWrapper {
//   margin: 0 auto;
//   max-width: 500px;
//   padding: 0 24px;
//   display: flex;
//   flex-direction: column;
//   height: 100vh;
// }

// .Demo {
//   flex: 1;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   padding-bottom: 40%;
// }


// .StripeElement {
//   display: block;
//   margin: 10px 0 20px 0;
//   background-color: #fafafa;
//   padding: 10px 14px;
//   font-size: 1em;
//   font-family: "Source Code Pro", monospace;
//   border-bottom: 2px solid #e2e8f0;
//   outline: 0;
// }

// .StripeElement--focus {
//     border-bottom: 2px solid #01896a;
//   -webkit-transition: all 150ms ease;
//   transition: all 150ms ease;
// }

// .StripeElement.IdealBankElement,
// .StripeElement.FpxBankElement,
// .StripeElement.PaymentRequestButton {
//   padding: 0;
// }

// .StripeElement.PaymentRequestButton {
//   height: 40px;
// }