.Topology {
    //box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
    //min-height: 60rem;
    //background-color: white;
    &__breadcrumbs {
        display: flex;
        padding: 2rem 2rem 2rem 2.5rem;
        font-size: 1.4rem;
        font-weight: 600;
        gap: 0.4rem;
        color: #103C67;
    }
    &__body {
        padding: 1.5rem 2.5rem;
        border-radius: .3rem;
        flex-direction: column;
        display: flex;
        gap: 1rem;
    }
}