.SearchBar {
  width: 100%;
  min-width: 5rem;
  max-width: 21rem;
  position: relative;
  

  &__Field {

      &-input {
       font-size: 1.3rem;
       width: 100%;
       min-height: 3rem;
       padding: .7rem 3.5rem .7rem 1.3rem;
       border: 1px solid rgba(241, 241, 241, 0.5); 
       border-radius: .2rem;
       background: white;
       outline: 1.3px solid rgba($color: #000000, $alpha: 0.1);
       color: #48c1fc;
       font-weight: 600;
       
       &::placeholder {
        //color: rgba(255, 255, 255, 0.85);
        color: #48c1fc;
       }
      }
  }

  &__Button {
      padding: .7rem .9rem;
      //width: 3.2rem;
      height: 100%;
      background: #374B5E;
      border: 2px solid #374B5E;
      border-radius: .3rem;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;

      &:active {
        outline: 3px solid #374B5E78;
      }
  }
}
