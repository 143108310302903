.EditSpaceTree {
    font-size: 1.3rem;
    margin: 1rem;
    &__rootNode {
        min-width: 200px;
        min-height: 35px;
        display: flex;
        align-items: center;
        padding: 0px 10px;
        border-radius: 3px;
        justify-content: space-between;
        background-color: #48C1FC1A;
        color:#2E3E4E;
        font-size: 1.3rem;

        &--name {
            display: flex;
        }

        &--actions {
            display: flex;
            gap: 5px;
            align-items: center;
            cursor: pointer;
            // justify-content: center;
            .fa {
                border: 1px solid #D7D7D7;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 20px;
                width: 20px;
                border-radius: 3px;
                color: #130F26;
                background-color: #D7D7D7;
                font-size: 1.2rem;

            }
        }
    }

    ul {
        list-style: none;
    }
    ul li {
        padding: 7px 0 7px 40px;
        position: relative;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        //line from vertical to fa-squre
        &::before {
            content: '';
            height: 1.5px;
            width: 39px;
            background-color: #D7D7D7;
            position: absolute;
            top: 16px;
            left: 0;
            margin: auto;
        }
        // vertical line 
        &::after {
            content: '';
            width: 1.5px;
            height: 100%;
            background-color: #D7D7D7;
            position: absolute;
            top: 0;
             bottom: 0;
             left: 0;
        }
        &:last-child {
            margin-bottom: 12px;
        }
        &:last-child, &:last-child::after {
            height: 16px;
        }

        & .fa {
            color: #D7D7D7;
            cursor: pointer;
        }
    }
   
    ul li &__childNode {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 10px;
        z-index: 1;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            left: -5px;
            top: -5px;
            bottom: -5px;
            right: 0px;
            background-color: #48C1FC1A;
            border-radius: 3px;
            z-index: -1;
            display: none;
        }
        &:hover::before {
            color: #2E3E4E;
            display: block;
        }
        &:hover {
            color: #2E3E4E;
            text-decoration: none;
            cursor: pointer;
        }

        &--toggle {
            font-size: 18px;
            color: #7C7B7B;
        }

        &--icon {
            color: #7C7B7B;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &--actions {
            color: #7C7B7B;
            gap: 6px;
            .fa {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 20px;
                width: 20px;
                border-radius: 3px;
                font-size: 12px;
                color: #130F26;
                background-color: #C4C4C4;
            }
            position: absolute;
            right: .6rem;
            top: 4.5px;
            display: none;
        }       
    }

    ul li .EditSpaceTree__childNode:hover .EditSpaceTree__childNode--actions{
        display: flex;
    }

    ul li .EditSpaceTree__childNode.node_edit {
        z-index: 10;

        &::before {
            background-color: #D7D7D7;
            display: block;
            border: 2px solid #D7D7D7;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
        }

        form.node_edit_form {
            position: relative;
            display: -webkit-flex;
            display: flex;
            height: 20px;

            .field {
                flex-basis: 200px;
                position: relative;

                // input {
                //     //width: 100%;
                //     font-family: inherit;
                //     line-height: 13px;
                //     height: 26px;
                //     padding: 0 10px 0 0;
                //     background-color: transparent;
                //     border: none;
                //     color: #000;
                //     position: relative;
                //     top: -3px;
                //     outline: none;
                //     border: 1px solid transparent;
                //     border-radius: 2px;
                //     padding: 10px 10px;
                //     margin-right: 10px;
                // }

                .code::before{
                    content: "";
                    display: inline-block;
                    width: 1px;
                    position: absolute;
                    left: -5px;
                    top: -4px;
                    bottom: -4px;
                    background-color: #e1e5e8;
                }
                &.action_node {
                    display: flex;
                    gap: 7px;
                    align-items: center;
                    justify-content: flex-end;

                    .fa {
                        width: 20px;
                        height: 20px;
                        border: 1px solid #D7D7D7;
                        border-radius: 3px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #D7D7D7;
                        font-size: 12px;
                    }

                    
                }
            }
        }

    }
}