//custom form style
.form__input__group {
  display: flex;
  font-size: 1.3rem;
  margin: 0 0rem 1rem 0rem;
  gap: 2rem;
}

.InputBox {
  background: none;
  padding: 0.3rem 0.5rem;
  border: 1.3px solid rgba($color: #000000, $alpha: 0.3);
  border-radius: 2px;
  outline: none;
  &:focus {
    outline: 1.5px solid #48c1fc;
  }
}

//for login, resgister and forgot password page
.form__input {
  background: #f8f8f8;
  border: 2px solid #5c5c5c80;
  box-sizing: border-box;
  border-radius: 5px;
  height: 61.32px;
  width: 100%;
  font-weight: 600;
  padding: 0 2rem;
  font-size: 16px;
  color: #48c1fc;
}
.form_field {
  position: relative;
  display: inline;
}
.form__input:hover {
  border-color: lightblue;
}
.form__input:focus {
  outline-color: #48c1fc;
}
.form__label {
  position: absolute;
  top: -0.7rem;
  left: 2rem;
  pointer-events: none;
  background-color: #f8f8f8;
  padding: 0 1rem;
  cursor: text;
  font-size: 20px;
  color: #353535cc;
  transition: top 0.2s ease-in, left 0.2s ease-in, font-size 0.2s ease-in;
  //text-transform: uppercase;
}

.form__input:focus ~ .form__label,
.form__input:not(:placeholder-shown).form__input:not(:focus) ~ .form__label {
  top: -3.7rem;
}
