.Pagination {
    &__list {
        list-style: none;
        display: flex;
        &--item {

            .list-btn {
                background: none;
                // border: 1px solid green;
                // padding: .2rem .5rem;
                border: none;
                min-height: 2.9rem;
                min-width: 2.9rem;
                outline: none;
                border-radius: 5px;
                color: #103C67;
                transition: all .2s;
                &:hover {
                    background: #48c0fc3d;
                    //color: white;
                    border-radius: 50%;
                }
            }
            .list-btn.selectedList {
                font-weight: bold;
                color: #48C1FC;
            }
            .list_btn-bg {
                background: #48C1FC1A;
                &:hover {
                    background: #48c0fc3d;
                    //color: white;
                    border-radius: 5px;
                }
            }
        }
    }
}