.SubscriptionPlan {
    &__container {
        font-size: 1.4rem;
        display: flex;
        flex-wrap: wrap;
        margin: 2rem 2.5rem;
        gap: 2rem;
    }

    &__backbtn {
        font-size: 1.3rem;
        padding: 2.5rem;
        padding-bottom: 0;
        cursor: pointer;
      }

    &__card {
        min-width: 35rem;
        //min-height: 30rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
        border-radius: 3px;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        

        &__header {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.7rem;
            font-weight: 600;
            padding: 1rem 2rem;
        }

        &__body {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            font-weight: 600;
        }

        &__btn {
            width: 100%;
            margin-top: 3rem;
            background: #48c1fc;
            color: white;
            padding: .5rem 3rem;
            border-radius: 3px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}