.form-group {
  font-size: 1.6rem;
}
.form-control {
  font-size: 1.6rem;
}

.btn {
  font-size: 1.2rem;

  &:disabled {
    cursor: no-drop;
  }
}

.alert {
  font-size: 1.6rem;
  box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.2);
}
