.DataTable {
    width: 100%;
    font-size: 1.3rem;
    font-weight: 600;
    //background-color: white;
    overflow: auto;

    &__filter {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 1rem;
        margin: 2rem 0;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        border-radius: .3rem .3rem .2rem .2rem;
        overflow: auto;
        thead {
            tr {
                background-color: #48C1FC0D;
                color: #2E3E4E;
                //color: #fff;
            }
        }
        th, td {
            text-align: left;
            padding: 1rem 0 1rem 1rem;
            color: #00000099;
        }

        tr {
            th.checkbox, td.checkbox {
                &:first-child {
                    padding: 0.7rem 0 0.7rem 1rem;
                    width: 4rem;
                }
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid rgba(72, 193, 252, 0.2);
                // &:nth-child(even) {
                //     background-color: #F9F9F9;
                // }
            }
        }
    }

    &__pagination {
        padding-top: 1.5rem;
        padding-right: 1rem;
        display: flex;
        justify-content: space-between;

        &--label {
            font-size: 1.3rem;
            display: flex;
            align-items: center;

            &-count {
                font-weight: 700;
                padding-left: .5rem;
                color:#40c1fc ;
            }
        }

        

    }
}