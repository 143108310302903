.CreateUser {
    width: 100%;
    &__box {
        display: flex;
        flex-direction: column;
        gap: 1rem
    }

    &__item {
        display: flex;
        width: 100%;
        flex-direction: row;
        gap: 2rem;
    }
}