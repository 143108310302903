.Payment {
    &__card {
        width: 45%;
        min-height: 30rem;
        //height: 70vh;
        margin: 2rem 2.5rem;
    }
    &__details {
        padding-top: 3rem;
        height: 100%;
        font-size: 1.4rem;
        font-weight: 600;
        display: flex;
        align-items: flex-center;
        justify-content: center;
    }

    .FormGroup {
        margin: 0 15px 20px;
        padding: 0;
        border-style: none;
        //background-color: #48c1fc;
        will-change: opacity, transform;
        box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #ececec;
        border-radius: 4px;
    }

    .FormRow {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        margin-left: 15px;
        //border-top: 1px solid #819efc;
        //border-top: 1px solid gray;
    }
    
    .StripeElement--webkit-autofill {
        background: transparent !important;
    }
    
    .StripeElement {
        width: 100%;
        padding: 11px 15px 11px 0;
    }
    
    button {
        display: block;
        font-size: 16px;
        width: calc(100% - 30px);
        height: 40px;
        margin: 40px 15px 0;
        background-color: #48c1fc;
        box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #48c1fc;
        border-radius: 4px;
        color: #fff;
        font-weight: 600;
        cursor: pointer;
        transition: all 100ms ease-in-out;
        will-change: transform, background-color, box-shadow;
        border: none;
        &:focus, &:visited {
            border: none;
            outline: none;
        }
        &:active {
            background-color: #19acf6;
            box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #19acf6;
            outline: none;
            border: none;
            transform: scale(0.99);
        }
    }
    
}

.payment_form {
    display: flex;
    flex-direction: column;
    gap:.5rem;
    min-width:15rem;
    border: 1px solid gray;
}

.paymentMethod-cards {
    
    margin: 2rem 2.5rem;

    &-saved {
        padding: 1rem 0rem;
        display: flex;
        gap: 1rem;
        font-size: 1.5rem;
        font-weight: 600;
        i{
            font-size: 2rem;
        }
    }

}

.relative-radio {
    position: relative;
    top: -.3rem
}





// .App {
// 	margin-left: 25%;
// 	margin-top: 5%;
// 	margin-right: 25%;
// }
