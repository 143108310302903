.ImportButton {
    width: auto;
    font-size: 1.3rem;

    &__input {
        height: 0;
        overflow: hidden;
        width: 0;
    }

    &__label {
        display: flex;
        gap: 1rem;
        min-width: 15rem;
        align-items: center;
        justify-content: center;
        padding: 0.4rem .8rem;
        border: 1.4px solid #374B5E;
        border-radius: 2px;
        color: #374B5E;
        cursor: pointer;

        svg {
            fill: #374B5E;
        }
    }
}