.BreadCrumbs {

    &__breadcrumbs {
        display: flex;
        padding: 2rem 2rem 2rem 2.5rem;
        font-size: 1.4rem;
        font-weight: 600;
        gap: 0.4rem;
        color: #103C67;
    }
}