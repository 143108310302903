.Dropdown {
    font-size: 1.3rem;
    min-width: 22rem;
    width: 100%;
    //overflow: hidden;
    position: relative;
    &__header {
        width: 100%;
        display: flex;
        gap: 1rem;
        padding: .7rem 1rem;
        border: 1.3px solid rgba($color: #000000, $alpha: 0.4);
        border-radius: 2px;
        position: relative;
        cursor: pointer;
        &:hover {
            border-color: rgb(72, 193, 252);
        }

        &--icon {
            position: absolute;
            right: .5rem;
            top: .8rem;
        }
    }

    &__body {
        display: block;
        width: 100%;
        background-color: white;
        box-shadow: 0 1rem 2rem rgba($color: #000000, $alpha: 0.25);
        position: absolute;
        left: 0;
        border-radius: 0 0 2px 2px;
        z-index: 23;
        max-height: 15rem;
        border: 1px solid rgba($color: #000000, $alpha: 0.3);
        overflow: auto;

        &--item {
            width: 100%;
            padding: 0.5rem 1rem;
            cursor: pointer;
            //transition: all .2s;
            &:hover {
                //box-shadow: 0 0 .7rem rgba($color: #000000, $alpha: 0.14);
                // transform: translateY(-1px);
                background-color: rgb(72, 193, 252);
                color: white;
            }
            &:not(:last-child) {
                border-bottom: 1px solid rgba($color: #000000, $alpha: 0.3);
            }
        }
    }

    &__body.close {
        display: none;
    }
}