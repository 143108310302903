.Main {
  margin-top: 0;
  padding-top: 0;
  width: 100%;
  height: calc(100vh - 6.5rem);
  display: flex;

  &__SideBar {
    width: 7.5rem;
    height: 100%;
    background: linear-gradient(174.45deg, #103c67 0%, #2e4d6b 101.12%);
    position: relative;
    transition: width 0.3s;
    overflow: auto;

    &--open {
      height: 4.5rem;
      padding-right: 1.7rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .fas {
        cursor: pointer;
        font-size: 2.4rem;
        color: #ffffffec;
        transition: transform 0.3s;
        &:hover {
          transform: scale(1.2);
        }
      }
    }

    &--closed {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &-tree {
        height: 6.5rem;
        width: 100%;
        background: #48c1fc;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(
          90deg,
          rgba(230, 230, 230, 0.2) 0%,
          rgba(230, 230, 230, 0.082) 100.84%
        );
        cursor: pointer;
      }

      &-item {
        height: 7rem;
        width: 100%;
        border: 1px solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(
          90deg,
          rgba(230, 230, 230, 0.2) 0%,
          rgba(230, 230, 230, 0.082) 100.84%
        );
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
  &__SideBar.open {
    width: 20%;
  }
  &__Main {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    &-item {
      width: 100%;
      height: 100%;
    }
  }
  &__Main.open {
    width: 80%;
    transition: width 0.3s;
  }
}


.open-close-menu {
  position: absolute;
  z-index: 2;
  top: 16px;
  left: 0px;
  background-color: #fff;
  display: block;
  width: 15px;
  height: 30px;
  padding: 8px 3px;
  text-align: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  transition: width 0.3s;

  &:hover {
    width: 20px;
    padding: 8px 5px;
  }
  i {
      color: #333;
  }
}