.PopUpModal {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    z-index: 12;
    font-size: 1.4rem;
    transform:scale(1);
    animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

    &__box {
        display: inline-block;
        position: relative;
        min-height: 25%;
        min-width: 25%;
        border-radius: .3rem;
        background-color: white;
        top: 10%;
        left: 50%;
        z-index: 13;
        box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
        animation: blowUpModal .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        //transform: translate(-50%, -10%);

        &--icon {
            position: absolute;
            right: .6rem;
            top: .6rem;
            padding: .4rem 1rem;
            color: #ADBECE;
            cursor: pointer;
            &:hover {
                color: white;
            }
        }

        &--header {
            width: 100;
            height: 4.5rem;
            font-size: 1.5rem;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            //background-color: #374B5E;
            color: white;
            background: linear-gradient(174.45deg, #103C67 0%, #2E4D6B 101.12%);
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
        }

        &--body {
            overflow: auto;
            max-height: 80vh;
            padding: 3rem;
        }

        &--button {
            width: 100;
            height: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 3rem;
            padding-right: 3rem;

            &-yes {
                border: 1px solid #C4C4C4;
                background-color: #C4C4C4;
                border-radius: .3rem;
                padding: .5rem 2rem;
                cursor: pointer;
            }

            &-no {
                border: 1px solid #C4C4C4;
                background-color: #C4C4C4;
                border-radius: .3rem;
                padding: .5rem 2rem;
                cursor: pointer;
            }
            
        }
    }
}