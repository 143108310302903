.Users {
  &__Label {
    display: flex;
    padding: 2rem 2rem 0rem 2.5rem;
    font-size: 1.4rem;
    font-weight: 600;
    gap: 0.4rem;
    color: #103c67;
  }
  &__toggleButton {
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
    align-items: center;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    margin-top: 2rem;
    height: 4rem;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);

    &--btn {
      display: flex;
      gap: 1.5rem;
    }
  }

  &__toggleWidget {
    display: flex;
    // gap: 2rem;

    &__list {
      margin-top: 2rem;
      margin-left: 2.5rem;
      width: 20%;
      &-label {
        font-size: 1.4rem;
        font-weight: 600;
        padding: 1rem 0 2.5rem 0;
      }
    }

    &__table {
      width: 80%;
    }

    &__table.tab100 {
      width: 100%;
    }

  }

}


