.ActivityLogs {
    //width: 60%;
    font-size: 1.3rem;
    margin: 2rem 0;
    position: relative;
    display: inline-flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 3rem;
    &__Item {
        display: flex;
        align-items: center;
        // flex: 0 0 auto;

        &__icon {
            // border: 1px solid red;
            // width: 6rem;
            padding: 0 2rem 0 0 ;
        }

        &__body {
            // border: 1px solid green;
            // width: 100%;
            flex: 0 0 auto;

            &--user {
                font-weight: 700;
            }
            &--time {
                font-size: 1rem;
                font-weight: normal;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &::before {
        content: "";
        border-left: 3px solid #48c1fc;
        
        position: absolute;
        z-index: -1;
        left: 3rem;
        top: 0;
        height: 100%;
    }
}