.PlanCard {
  // width: 100%;
  min-width: 30rem;
  //min-height: 30rem;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &__action {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.7rem;
    font-weight: 600;
    padding: 1rem 2rem;
    text-transform: uppercase;
  }

  &__body {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    font-weight: 600;

    &__details {
        max-width: 25rem;
        font-size: 1.3rem;
        font-weight: 500;
        
    }
  }

  &__priceText {
      font-size: 2.8rem;

      span {
          font-size: 1.3rem;
      }
  }

  &__btn {
    width: 100%;
    margin-top: 3rem;
    background: #48c1fc;
    color: white;
    padding: 0.5rem 3rem;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
