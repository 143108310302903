.DeviceModelProperties {
  margin-top: 1rem;

  // &__label {
  //   padding: 0.6rem 2rem;
  //   border-radius: 2px;
  // }

  &__toolbox {
    //padding: 0.6rem 2rem;
    height: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: #2E3E4E;
    
    &--tools {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }

  &__table {
    &--addbtn {
      //
      padding: 2rem 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 1rem;
    }
  }
}
