.SpacesData {
  //width: 100%;
  &__addbtn {
    //margin-bottom: 2.5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    height: 4rem;
    position: relative;
    &__select {
      position: absolute;
      right:2.5rem;
      top: 6rem;
      z-index: 1;
    }
    &--filter {
      display: flex;
      justify-content: start;
      gap: 1.5rem;
    }
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  }

  &__table {
    margin: 0 2.5rem;
    position: relative;
    // padding: 2rem;
    // background: #ffffff;
    // box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
    //width: 80%;
  }
}
.table__loader {
  //display: inline-block;
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translate(-50%),-50%;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.08);
}
.tableNav {
  cursor: pointer;
  transition: all .2s;
  &:hover {
    //transform: translateY(-1px);
    color: #48c1fc;
  }
}
