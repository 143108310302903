.Button {
    font-size: 1.3rem;
    &__button {
        border: none;
        background-color: #374B5E;
        color: white;
        padding: .6rem 1.6rem;
        border-radius: 3px;

        &:focus, &:visited {
            border: none;
            outline: none;
        }
        &:active {
            outline: 3px solid #374B5E78;
        }
    }

    &__button.cancel {
        box-sizing: border-box;
        border: 2px solid #374B5E;
        background: white;
        color: #374B5E;
        padding: .4rem 1rem;
        &:active {
            outline: 3px solid #374B5E78;
        }
    }

    &:not(:last-child) {
        margin-right: 1rem;
    }
}