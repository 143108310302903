.ForgotPassword {
    width: 30vw;
    height: 80vh;

    &__text {
        margin-bottom: 5rem;
        font-size: 20px;
        text-align: center;
        color: rgba(35, 70, 106, 0.5);
    }

    &__label {
        font-weight: 700;
        font-size: 3rem;
        line-height: 4.1rem;
        color: #23466a;
        text-align: center;
        padding-bottom: 4rem;
    }
}