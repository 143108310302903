.Textarea {
  textarea {
    width: 100%;
    background: none;
    padding: 0.6rem 1rem;
    border: 1.3px solid rgba($color: #000000, $alpha: 0.2);
    border-radius: 2px;
    outline: 2px solid transparent;
    color: #48c1fc;

    &:hover {
      border-color: lightblue;
    }

    &:focus {
      outline: 2px solid #48c1fc9f;
      border: 1.3px solid rgba($color: #000000, $alpha: 0);
    }
    &:disabled {
      color: rgba($color: #000000, $alpha: 0.5);
      user-select: none;
      cursor: no-drop;
      border-color: rgba($color: #000000, $alpha: 0.2);
    }
  }
}
