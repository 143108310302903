.Header {
  width: 100%;
  height: 6.5rem;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  box-shadow: 0px 0.4rem 1rem rgba(0, 0, 0, 0.15);
  z-index: 2;
  position: relative;
  user-select: none;

  &__right {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-right: 1.3rem;
    gap: 2.5rem;

    &-bell {
      cursor: pointer;
    }

    &-userIcon {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      border: 1px solid #23466a;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
      font-size: 1.4rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #23466a;
      color: #ffffff;
      overflow: hidden;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0);
        transform: scale(1.1);
      }
      &:active {
        outline: 3px solid #374B5E78;
      }
    }
  }

  &__popup {
    position: absolute;
    padding: 2rem 7rem;
    box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.2);
    background-color: white;
    border-radius: 0.3rem;
    top: 6.6rem;
    right: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100;
    gap: 2rem;
    animation: blowUpModalUser .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

    &--userIcon {
      height: 60px;
      width: 60px;
      border-radius: 50%;
      border: 1px solid #23466a;
      //box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
      font-size: 2.4rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #23466a;
      color: #ffffff;
      overflow: hidden;
      position: relative;
      cursor: pointer;

      &-edit {
        position: absolute;
        font-size: 1.3rem;
        top: 0;
        right: 0.6rem;
        border-radius: 50%;
        color: #48c1fc;
        z-index: 34;
      }
    }

    &--logoutbtn {
        padding-top: 3rem;
      }

    &--useremail {
      font-size: 1.6rem;
      font-weight: 700;

      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
}
