.HomePage {
  height: calc(100vh - 6.5rem);
  position: relative;
  overflow: hidden;
  .list {
    padding: 5px 0;

    li {
      position: relative;
      list-style-type: none;
      background-color: #fff;
      padding: 15px;
      margin-bottom: 10px;
      box-shadow: 0px 0px 10px 0px #d7d7d7;
      button {
        position: absolute;
        right: 10px;
        top: 12px;
      }
    }
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 10% 100%;
  }
  &__layer {
    // z-index: 2;
    position: absolute;
    background: rgba(35, 70, 106, 0.6);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &__hero {
      position: absolute;
      //z-index: 1;
      font-size: 7rem;
      font-weight: 800;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #48c0fcda;
      text-transform: uppercase;
  }
}
