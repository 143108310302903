.Devices {
  margin: 0 2.5rem;
  &__tools {
    //padding: 0 2.5rem;
    height: 7rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-icons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 1rem;
    }

    &-search {
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 1.5rem;
    }
  }

  &__table {
    &--label {
      font-size: 1.3rem;
      padding: 1rem 0 1rem 1rem;
    }
  }
}

.downloadBtnList {
  list-style: none;
  margin: 1rem 2rem;

  li {
      //padding: 1em 2rem;
      margin: 1rem 0;
  }
}
.downLoad__listBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
}

