.Poster {
    width: 100vw;
    height: 100vh;
    min-height: 65rem;
    font-size: 1.4rem;
    overflow: auto;
    display: flex;

    &__poster {
        width: 50%;
        position: relative;
        overflow: hidden;
        &--image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &--layer {
            z-index: 2;
            position: absolute;
            background: rgba(35, 70, 106, 0.6);
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        &--logo {
            z-index: 4;
            min-height: 20rem;
            height: 70vh;
            width: 50rem;
            background: rgba(3, 40, 78, 0.4);
            border: 1px solid #FFFFFF;
            box-shadow: 0px 20px 10px rgba(64, 64, 64, 0.15);
            border-radius: 20px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            &-img {
               position: absolute;
               top: 50%;
               left: 50%;
               transform: translate(-50%, -50%);
            }
        }
    }
    &__form {
        background: #F8F8F8;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: auto
    }
}