.DeviceConnect {
  border-radius: 3px 3px 2px 2px;
  &__devicetable {
    width: 50%;
    font-size: 1.3rem;
    &--label {
      color: #2E3E4E;
      font-size: 1.4rem;
      font-weight: 600;
    }

    &--devicebtn {
      padding: 0.4rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      &-btn svg path{
        fill: #2e4d6b;
      }
    }
  }
}

.Unpair__device {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &--label {
    padding: 2rem 4rem;
    font-size: 1.6rem;
    font-weight: 600;
  }

  &--footer {
    display: flex;
    padding-top: 3rem;
    justify-content: flex-end;
  }
}

.Pair_device {
  padding: 1rem;
  font-size: 1.3rem;
  &__form {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;

    &--input {
      // width: 50%;
      max-width: 30rem;
      &-description {
        font-size: 1.2rem;
      }
    }
  }

  &__importBtn {
    padding-top: 0rem;
    display: flex;
    justify-content: flex-end;
  }

  &__footer {
    padding-top: 5rem;
    display: flex;
    justify-content: flex-end;
  }
}
