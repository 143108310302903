.SpaceTree {
    font-size: 1.3rem;
    &__rootNode {
        min-width: 200px;
        min-height: 40px;
        display: flex;
        align-items: center;
        padding: 0 2rem;
        justify-content: space-between;
        background: #446689;
        color:#FFFFFF;
        font-size: 1.2rem;

        &--name {
            display: flex;
        }

        &--actions {
            display: flex;
            gap: 5px;
            align-items: center;
            cursor: pointer;
            .edit {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 26px;
                width: 26px;
                background: #FFFFFF;
                border: 1px solid rgba(25, 44, 215, 0.15);
                border-radius: 2px;

            }
        }
    }
    ul {
        list-style: none;
    }
    ul li {
        padding: 8px 0 0 20px;
        position: relative;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        //line from vertical to fa-squre
        &::before {
            content: '';
            height: 1.5px;
            width: 10px;
            background-color: #FFFFFFCC;
            position: absolute;
            top: 18px;
            left: 0px;
            margin: auto;
        }
        //vertical line 
        &::after {
            content: '';
            width: 1.5px;
            background-color: #FFFFFFCC;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0px;
        }
        // &:first-child::after {

        // }
        &:last-child::after {
            height: 18px;
            background-color: #FFFFFFCC;
        }

        & .fa {
            color: #FFFFFFCC;
            cursor: pointer;
        }
    }
   
    ul li &__childNode {
        margin-left: -1rem;
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 5px;
        z-index: 1;
        position: relative;
        color: #FFFFFFCC;

        &::before {
            content: "";
            position: absolute;
            left: -5px;
            top: -5px;
            bottom: -5px;
            right: 0px;
            background-color: #48C1FC1A;
            border-radius: 3px;
            z-index: -1;
            display: none;
        }
        &:hover::before {
            color: white;
            display: block;
        }
        &:hover {
            color: white;
            text-decoration: none;
            cursor: pointer;
        }

        &--toggle {
            font-size: 1.8rem;
            color: #7C7B7B;
        }

        &--icon {
            min-height: 2.8rem;
            min-width: 2.8rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #446689;
            border-radius: 5px;
        }
     
    }
    ul li &__childNode.active {
        color: white;
        &::before {
            background-color: #48c0fc50;
            color: white;
            display: block;
        }
    }
}