.EditSpace {
    min-height: 80vh;
    margin: 2.5rem;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
    padding: 2.5rem;
    border-radius: 3px;
    background-color: white;

    &__backbtn {
        font-size: 1.3rem;
        padding-left: .5rem;
        padding-bottom: 1rem;
        cursor: pointer;
    } 
    &__tree {
        width: 40%;
    }
}