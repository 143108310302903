.DeviceModel {
  margin: 2rem 2.5rem;
  &__body {
    display: flex;
    gap: 2rem;

    &--datatable {
      width: 60%;
      min-height: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;

      &-noRecords {
        font-size: 1.3rem;
        font-weight: 700;
      }
    }
    &--addButton {
      width: 50%;
    }
  }
}
