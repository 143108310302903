.BrowserResetPassword {
    //margin: 0 auto;
    width: 100%;
    height: 100vh;
    font-size: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: auto;
    gap: 2rem;
    &__box {
        padding: 3rem 3rem;
        border-radius: .5rem;
        min-width: 45rem;
        margin: 0.5rem;
        width: auto;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        background: white;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);

        &--label {
            font-size: 1.8rem;
            font-weight: bold;
            // align-self: center;
        }

        @media (max-width: 576px) {
            /* For mobile phones: */
            min-width: 90vw;
            padding: 3rem 2rem;
        }
    }

    &__item {
        //max-width: 50rem;
        width: 100%;
        display: flex;
        flex-direction: column;

        &--label {
            font-size: 1.4rem;
        }
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}