// ouline button (add, firmware update)
// label {
//   font-weight: 600;
//   color: #2E3E4E;
// }
.btn_outline {
  font-size: 1.3rem;
  padding: 0.6rem 1.4rem;
  outline: none;
  border: 2px solid #2e4d6b;
  font-weight: 600;
  background: #ffffff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  color: #2e4d6b;
  cursor: pointer;
  transition: all 0.3s;

  &:visited,
  &:active,
  &:focus {
    outline: none;
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.08);
    outline: 3px solid #374B5E78;

  }
 
  &:hover {
    transform: translateY(-1px);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  }
}

// popup footer button
.popup__btn__footer {
  padding-top: 5rem;
  display: flex;
  justify-content: flex-end;
}

// icon outline button (export import delete)
.border_icon {
  display: inline-block;
  padding: 0.4rem 0.6rem;
  border-radius: 3px;
  border: 2px solid #2e4d6b;
  background-color: white;
  cursor: pointer;
  transition: all 0.3s;

  &.btn {
    border: 1px solid #2e4d6b;
    font-size: 1.3rem;
    //color: gray;
  }

  &.color_green {
    border: 2px solid #43A047;
    background-color: #43A047;
    color: white;
  }
  &.color_yellow {
    border: 2px solid #FF9800;
    background-color: #FF9800;
    color: white;
  }
  &.color_red {
    border: 2px solid #D32F2F;
    background-color: #D32F2F;
    color: white;
  }
  &.active {
    border: 2px solid #2e4d6b;
    background-color: #2e4d6b;
    color: white;
  }

  &:hover {
    transform: translateY(-1px);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  }
}
//full width button used in login, register and forgot password page!
.big__button {
    font-size: 1.6rem;
    width: 100%;
    height: 5.6rem;
    border: none;
    color: white;
    background: #479AD5;
    border-radius: .8rem;
    transition: all 0.3s;
    &:focus {
        border: none;
        outline: none;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0);
    }
    &:hover {
      transform: translateY(-1px);
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    }
    &:active {
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0);
      transform: translateY(1px);
    }
  }
