.CreateRole {
    // margin: 2rem 2.5rem;
    font-size: 1.4rem;

    &__form {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 3rem;
        
    }
    &__footer {
        margin-top: 2rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}

.tableChexBox {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}
