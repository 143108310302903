.ListNavBar {
    font-size: 1.4rem;
    max-height: 90vh;
    overflow: auto;
//    line-height: 1.8rem;
    &__list {
        list-style: none;
        //margin-top: 1rem;
        font-size: 1.3rem;
        // border-left: 1px solid rgba(72, 193, 252, 0.2);
        // border-right: 1px solid rgba(72, 193, 252, 0.2);
        &--item {
            padding: .8rem 2rem;
            border-bottom: 1px solid rgba(72, 193, 252, 0.2);
            border-left: 3px solid transparent;
            cursor: pointer;
            transition: transform .2s;
            &:first-child {
                border-top: 1px solid rgba(72, 193, 252, 0.2);
            }
            &:hover {
                //transform: scale(1.1);
                background-color: #48c0fc13;

                .EditTenant {
                    display: block;
                }
            }
        }
        &--item.active {
            border-left: 3px solid #48C1FC;
            background-color: #48C1FC1a;
        }
    }
    .EditTenant {
        display: none;
    }
}