.DeviceDetails {
  margin: 2.5rem 2.5rem;
  display: flex;
  gap: 2.5rem;
  flex-direction: column;
  &__details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 3rem;
  }
  &__backbtn {
    font-size: 1.3rem;
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
    cursor: pointer;
  }
  &__devicetable {
    &--activiylogs {
      font-size: 1.4rem;
      font-weight: 600;
      margin-top: 2rem;
    }
  }
}
