.JobDetails {
    &__card {
        margin: 2.5rem 2.5rem;
    }

    &__statusBtn {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 1.5rem;
    }
}