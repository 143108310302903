.FotaJobs {
    &__statusBtn {
        display: flex;
        justify-content: start;
        gap: 1rem;
        margin-bottom: 1rem;

        &-filter {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
        }
    }
}