.Device {
  // &__body {
  //   padding: 1.5rem 2.5rem;
  //   border-radius: 0.3rem;
  //   flex-direction: column;
  //   display: flex;
  //   gap: 1rem;
  // }
  &__toggleButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    height: 4rem;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
    position: relative;

    &__select {
      position: absolute;
      top: 6rem;
      right: 2.5rem;
    }
  }
}
