.AddDeviceModel {
    font-size: 1.3rem;
    &__form {
        margin-bottom: 1rem;
        display: flex;
        gap: 3rem;
    }

    &__importBtn {
        padding-top: 0rem;
        display: flex;
        justify-content: flex-end;
    }

    &__footer {
        padding-top: 5rem;
        display: flex;
        justify-content: flex-end;
    }
}