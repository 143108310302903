.ToggleButton {
    font-size: 1.4rem;
    &__button {
        // width: 30rem;
        padding: .8rem 3rem;
        color: #00000066;
        border: none;
        border-bottom: 3px solid transparent;
        background: #FFFFFF;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
        &:not(:last-child) {
            margin-right: .2rem;
        }
        &:active, &:focus, :visited {
            border: none;
            outline: none;
        }
        &:hover {
            color: #103C67;
        }

    }
    &__button.active {
        border-bottom: 3px solid #48C1FC;
        color: #103C67;
    }
   
}