.RoleManage {
    &__Label {
        display: flex;
        padding: 2rem 2rem 2rem 2.5rem;
        font-size: 1.4rem;
        font-weight: 600;
        gap: 0.4rem;
        color: #103C67;
    }
    &__toggleButton {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        height: 4rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
      }
}